import { MSAppPage } from './MSAppPage';
/**
 * Cette classe représente un conteneur de panneaux qui hérite des fonctionnalités de base d'une page (MSAppPage) et ajoute des fonctionnalités spécifiques à la gestion de plusieurs panneaux et de leur affichage en fonction du hash de la query string de l'URL.
 */
export class MSPaneContainer extends MSAppPage {

    m_panes; //Un objet pour stocker les panneaux de la page par leur référence.
    m_default_pane_ref; //La référence du panneau par défaut.
    m_current_pane; //Une référence au panneau actuellement ouvert.
    $m_pane_container; //Une référence jQuery au conteneur des panneaux.

    constructor() {
        super();
        this.m_panes = {};

    }
    // Enregistre un panneau avec sa référence dans m_panes.
    registerPane(iPane, iRef) {
        this.m_panes[iRef] = iPane;
    }
    //Méthode appelée lors de l'ouverture du conteneur de panneaux.
    onOpen(iQuery) {
        this.onQueryChanged(iQuery);
    };
    //Méthode appelée lorsqu'il y a un changement dans la query string de l'URL associée à la page. Cette méthode détermine quel panneau doit être ouvert en fonction du hash de la query string.
    onQueryChanged(iQuery) {
        let new_pane = this.m_panes[iQuery.hash];
        if (!new_pane) {
            new_pane = this.m_panes[this.m_default_pane_ref];
        }
        if (!new_pane) {
            console.error('Missing default pane ref');
            return;
        }
        if (this.m_current_pane != new_pane) {
            this.openPane(new_pane, iQuery);
        } else {
            this.m_current_pane.onQueryChanged(iQuery);
        }
    }
    //Ouvre un panneau spécifique en fonction du hash de la query string de l'URL. Cette méthode crée également le panneau si nécessaire et gère son affichage.
    openPane(iPage, iQuery) {
        if (!this.$m_pane_container) {
            console.error('Missing pane container');
            return;
        }
        if (!iPage.$m_div) {
            iPage.$m_div = iPage.$create(iQuery);
            iPage.$m_div.addClass('pane').hide();
            iPage.$m_div.appendTo(this.$m_pane_container);
            iPage.onAfterCreate(iQuery);

        }
        iPage.onBeforeOpen(iQuery);
        if (this.m_current_pane) {
            this.m_current_pane.onClose();
            this.m_current_pane.$m_div.hide();
        }
        this.m_current_pane = iPage;
        iPage.onOpen(iQuery);
        iPage.$m_div.show();
        iPage.onAfterOpen(iQuery);
    }
}
