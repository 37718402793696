import { MSAppPage } from '../../ms_front_lib/app/MSAppPage';
import Mustache from "mustache";
import page_content from "./PageLegalNotices.html";
import './PageLegalNotices.css'

/*
http://localhost:8081/#login?email=landre3567@gmail.com&ws_stage=local&db_stage=local
*/

export class PageLegalNotices extends MSAppPage {

    $create(iQuery) {

        let html = gettext_localize(page_content);
        html = Mustache.render(html, (id) => {
            console.warn("mustache:" + id);
            return id;
        });
        const $res = $(html);
        this.$m_pane_container = $res.find("#pane_container");
        return $res;
    }
    onOpen(iQuery) {
        function addMetaDescription() {
            const descriptionContent = "Découvrez les mentions légales de Monnaie Services, incluant les informations sur l'éditeur du site, l'hébergeur et notre politique de protection des données personnelles.";
            let metaDescription = $('meta[name="description"]');

            if (metaDescription.length) {
                metaDescription.attr('content', descriptionContent);
            } else {
                metaDescription = $('<meta>').attr('name', 'description').attr('content', descriptionContent);
                $('head').append(metaDescription);
            }
        }
        $(function () {

            if ($('.footerPage').length === 0) {
                $('.conditional_side_nav').show()
                $('#conditional_footer_legal').show()
            } else {

                $('.conditional_side_nav').hide()
                $('#conditional_footer_legal').hide()
            }
        });
        $(function () {

            $('.bi-arrow-up').parent('.btn').on('click', function () {
                $('.container-fluid').animate({
                    scrollTop: $('.container-fluid').scrollTop() + $('.scroll-top-anchor-ln').position().top
                }, 'slow');
            });


            $('.bi-arrow-down').parent('.btn').on('click', function () {
                $('.container-fluid').animate({
                    scrollTop: $('.container-fluid').scrollTop() + $('.scroll-bottom-anchor-ln').position().top - $('.container-fluid').height()
                }, 'slow');
            });
        });



    }
}
