import { MSIcon } from "../components/MSIcon";
export class FormsTheme {

    $createArrayPlusButton() {
        let $button=$("<div/>").addClass("btn");
        MSIcon.$("square-plus-solid").addClass("m-auto").appendTo($button);   
        return $button;
    }

    $createArrayDeleteButton() {
        let $button=$("<div/>").addClass("btn");
        MSIcon.$("trash-can-xmark-duotone-solid").addClass("m-auto").appendTo($button);   
        return $button;
    }

}



