import { MSAppPage } from '../../ms_front_lib/app/MSAppPage';
import Mustache from "mustache";
import page_content from "./PageAbout.html";
import './PageAbout.css'

/*
http://localhost:8081/#login?email=landre3567@gmail.com&ws_stage=local&db_stage=local
*/

export class PageAbout extends MSAppPage {

    $create(iQuery) {

        let html = gettext_localize(page_content);
        html = Mustache.render(html, (id) => {
            console.warn("mustache:" + id);
            return id;
        });
        const $res = $(html);
        this.$m_pane_container = $res.find("#pane_container");
        return $res;
    }
    onOpen(iQuery) {
        function addMetaDescription() {
            const descriptionContent = "Monnaie Services : vente de matériel informatique et consommables, services de maintenance. Qualité, développement durable (label QUALIOPI), solutions adaptées.";
            let metaDescription = $('meta[name="description"]');

            if (metaDescription.length) {
                metaDescription.attr('content', descriptionContent);
            } else {
                metaDescription = $('<meta>').attr('name', 'description').attr('content', descriptionContent);
                $('head').append(metaDescription);
            }
        }
        $(function () {
            // Vérifie si l'élément footer existe
            if ($('.footerPage').length === 0) {
                $('.conditional_side_nav').show()
                $('#conditional_footer_about').show()
            } else {
                // Si le footer existe déjà
                $('.conditional_side_nav').hide()
                $('#conditional_footer_about').hide()
            }
        });
        $(function () {

            $('.bi-arrow-up').parent('.btn').on('click', function () {
                $('.container-fluid').animate({
                    scrollTop: $('.container-fluid').scrollTop() + $('.scroll-top-anchor-ab').position().top
                }, 'slow');
            });


            $('.bi-arrow-down').parent('.btn').on('click', function () {
                $('.container-fluid').animate({
                    scrollTop: $('.container-fluid').scrollTop() + $('.scroll-bottom-anchor-ab').position().top - $('.container-fluid').height()
                }, 'slow');
            });
        });
        $(function () {
            function checkWidth() {
                if ($(window).width() < 768) {
                    $('.conditional_side_nav').css('display', 'none');
                } else {
                    return ''
                }
            }


            checkWidth();


            $(window).resize(function () {
                checkWidth();
            });
        });


        addMetaDescription();
    }
}