import { JsonRpcClient } from '../ms_front_lib/utils/jsonrpc_client';
export { JsonRpcError, JsonRpcErrorCode } from '../ms_front_lib/utils/jsonrpc_client';
import { Toast } from './toast';

export class Api extends JsonRpcClient {

    constructor() {
        super('', 5000);
    }

    async fetch(iMethod, iParams, iOptions = {}) {
        const auth_token = gl_app.authToken();
        this.setBearer(auth_token ? auth_token.id : false)
        if (!iOptions.url) {
            if (auth_token) {
                iOptions.url = auth_token.web_service_url;
            } else {
                throw new Error("Missing url in fetch");
            }
        } else if (iOptions.url[0] == '/') { // relative
            if (auth_token) {
                iOptions.url = auth_token.web_service_url + iOptions.url;
            } else {
                iOptions.url = this.defaultWsUrl() + iOptions.url;
                //throw new Error("Missing url in fetch");
            }
        }

        if (iOptions.no_wait_screen) {
            return super.fetch(iMethod, iParams, iOptions);
        }

        try {
            if (!this.$m_wait_overlay) {
                this.$m_wait_overlay = $('<div class="wait_overlay"/>').appendTo($('body'));
            }
            this.$m_wait_overlay.show();
            return await super.fetch(iMethod, iParams, iOptions);
        } finally {
            this.$m_wait_overlay.hide();
        }
    }

    defaultWsUrl() {
        if (window.location.host.substring(0, 9) == 'localhost' || window.location.host.substring(0, 2) == 'l-') {
            return this.wsUrl('local');
        }
        if (window.location.host.substring(0, 2) == 'b-') {
            return this.wsUrl('beta');
        }
        return this.wsUrl('prod');
    }

    wsUrl(iStage) {
        switch (iStage) {
            case 'local': return 'https://l-ws.imspass.com';
            case 'beta': return 'https://b-ws.imspass.com';
        }
        return 'https://ws.imspass.com';
    }


    async getEmailInfo(iWsStage, iDbStage, iEmail) {
        return this.fetch('get_email_info', { email: iEmail, db_stage: iDbStage }, { url: this.wsUrl(iWsStage) + '/bo_login' });
    }

    async sendVerificationEmail(iAction, iWsStage, iDbStage, iEmail, iRegistrationUrl) {
        return this.fetch('send_verification_email', { action: iAction, email: iEmail, db_stage: iDbStage, registration_url: iRegistrationUrl }, { url: this.wsUrl(iWsStage) + '/bo_login' });
    }

    async resetUserPassword(iWsStage, iEmail, iToken, iPasswordHash) {
        return this.fetch('reset_user_password', {
            email: iEmail,
            token: iToken,
            password_hash: iPasswordHash
        }, { url: this.wsUrl(iWsStage) + '/bo_login' });
    }

    async loginWidthPassword(iWsStage, iDbStage, iEmail, iPasswordHash, iGroupId = '') {
        return this.fetch('login_with_password', {
            email: iEmail,
            db_stage: iDbStage,
            password_hash: iPasswordHash,
            group_id: iGroupId,
            browser_id: window.browser_id,

        }, { url: this.wsUrl(iWsStage) + '/bo_login' });
    }

    async ssoLogin(iWsStage, iDbStage, iType, iParams) {
        return this.fetch('sso_login', {
            db_stage: iDbStage,
            browser_id: window.browser_id,
            type: iType,
            params: iParams,
        }, { url: this.wsUrl(iWsStage) + '/bo_login' });
    }

    async logout() {
        return this.fetch('logout_user', {
            browser_id: window.browser_id,
        }, {
            timeout: 1000,
            url: '/bo_login',
        });
    }

    async refreshAuthToken(iGroupId) {
        return this.fetch('refresh_auth_token', {
            group_id: iGroupId,
        }, { url: '/bo_login' });
    }

    async patchAccount(iPatch) {
        return this.fetch('patch_account', iPatch, { url: '/bo_login' });
    }

    async listManagedUsers(iGroupId = '') {
        return this.fetch('list_managed_users', {}, { url: '/bo_login' });
    }

    async listConsumers() {
        return this.fetch('list_consumers', {}, { url: '/bo_login' });
    }

    async listEmitters() {
        return this.fetch('list_emitters', {}, { url: '/admin' });
    }

    async activateEmitter(id, iActivate, iFee) {
        return this.fetch('activate_emitter', {
            emitter_id: id,
            active: iActivate,
            fee: iFee,
        }, { url: '/admin' });
    }

    async addUserToGroup(iEmail, iGroupId) {
        return this.fetch('add_user_to_group', {
            email: iEmail,
            group_id: iGroupId,
        }, { url: '/bo_login' });
    }

    async removeUserFromGroup(iEmail, iGroupId) {
        return this.fetch('remove_user_from_group', {
            email: iEmail,
            group_id: iGroupId,
        }, { url: '/bo_login' });
    }

    async getPublicPrint(iPrintId) {
        return this.fetch('get_printed_vouchers', {
            print_id: iPrintId,
        }, { url: '//public' });
    }


    /////////////////////
    // Orders
    /////////////////////

    async initOrder(iEmitterId, iVoucherCount) {
        return this.fetch('init_order', {
            emitter_id: iEmitterId,
            voucher_count: iVoucherCount,
        }, { url: '/consumer' });
    }

    async processOrder(orderID, orderAmount, orderCurrency, userEmail) {
        //payment_id : 'payzen:'+payment_id
        return this.fetch('process_order', {
            order_id: orderID,
            payment_id: `fake:1!${orderAmount}!${orderCurrency}!${orderID}!${userEmail}`
        }, { url: '/consumer' });
    }

    async listOrders() {
        return this.fetch('list_orders', {
            only_active: false,
            min_date_ms: 0,
            max_date_ms: 9999999999999
        }, { url: '/consumer' });
    }

    async createPayzenPayment(iOrderID) {
        return this.fetch('create_payzen_payment', {
            order_id: iOrderID
        }, { url: '/consumer' });
    }

    /////////////////////
    // Print
    /////////////////////

    async printVouchers(orderID, voucherCount, freeVouchers, email) {
        return this.fetch('print_vouchers', {
            order_id: orderID,
            voucher_count: voucherCount,
            use_free_vouchers: freeVouchers,
            email: email
        }, { url: '/consumer' });
    }

    async listOrderPrint(orderID) {
        return this.fetch('list_order_prints', {
            order_id: orderID,
        }, { url: '/consumer' });
    }

    async getPrintedVouchers(printID) {
        return this.fetch('get_printed_vouchers', {
            print_id: printID,
        }, { url: '/consumer' });
    }

    async listPrints(orderID, owner, operatorId, minDateMs, maxDateMs) {
        return this.fetch('list_prints', {
            _order_id: orderID,
            owner: owner,
            _operator_id: operatorId,
            _min_date_ms: minDateMs,
            _max_date_ms: maxDateMs,
        }, { url: '/consumer' });
    }
    async sendPrint(print_id, email) {
        return this.fetch('send_print', {
            print_id: print_id,
            email: email
        }, { url: '/consumer' });
    }

    /////////////////////
    // Patch Consumer
    /////////////////////
    async patchConsumer(iPatch) {
        return this.fetch('patch_consumer', iPatch, { url: '/consumer' });
    }


    /////////////////////
    // Utils
    /////////////////////


    async showError(iException) {
        return MSModal.alert(_l('Erreur'), iException.message);
    }

    async toastError(iMessage, iException = false) {
        let html = '<div class="toast_message">' + htmlent(iMessage) + '</div>';
        if (!iException) {
            html += '<div class="toast_sub_message">' + htmlent(iException.message) + '</div>';
        }
        Toast.create({
            role: "alert",
            class: "error",
            html: html,
        });
    }

}

