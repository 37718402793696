import { MSApp } from '../ms_front_lib/app/MSApp';
import { MSIcon } from '../ms_front_lib/components/MSIcon';

// load used forms
import { load as load_floating_labels_fields } from '../ms_front_lib/forms/FloatingLabelFields';
import { load as load_containers_fields } from '../ms_front_lib/forms/ContainerFields';
import { load as load_misc_fields } from '../ms_front_lib/forms/MiscFields';

// load app pages 
import { PageMain } from './pages/PageMain';
import { PageLogin } from './pages/PageLogin';
import { PageWelcome } from './pages/PageWelcome';
import { PageAccount } from './pages/PageAccount';
//import { PageUserOrder } from './pages/PageUserOrder';
import { PageLegalNotices } from './pages/PageLegalNotices';
import { PageCgv } from './pages/PageCgv';
import { PageAbout } from './pages/PageAbout';
const pack = require('../package.json');

import { PageAuthToken } from './pages/PageAuthToken';

export class App extends MSApp {

    $m_sidebar;
    m_side_bar_items = [];

    constructor() {
        super();

        this.commit = pack.unit.version;
        this.version = pack.version;
        this.stage = pack.unit.stage;
        this.is_secure_ip = pack.unit.stage == 'local';

    }

    /*
    Initialisation
   */

    async start() {
        console.log('app started');

        load_floating_labels_fields();
        load_containers_fields();
        load_misc_fields();

        // Create main page
        this.m_main_page = new PageMain();

        // Register hashes
        this.registerPage(new PageWelcome(), 'welcome');
        this.registerPage(new PageLogin(), 'login');
        this.registerPage(new PageLegalNotices(), 'legal_notices');
        this.registerPage(new PageCgv(), 'ims_cgv');
        this.registerPage(new PageAbout(), 'about');
        //        this.registerPage(new PageUserOrder(), 'user_order');

        this.registerPane(new PageAccount(), 'account');

        if (pack.stage != 'prod') {
            this.registerPane(new PageAuthToken(), 'auth_token');
            this.registerHashFunction('test', async () => {
                alert(await MSModal.ask("title", 'message', ['YES', 'NO']))
            });
        }

        this.registerHashFunction('logout', async () => {
            if (this.authToken()) {
                try {
                    await gl_api.logout();
                } catch (err) {
                    console.error('failed to  logout', err);
                } finally {
                    this.setAuthToken(false);
                }
            }
            this.setHash('#welcome', true);
        });

        this.registerHashFunction('renew_auth', async (iQuery) => {
            try {
                const res = await gl_api.refreshAuthToken(iQuery.group);
                gl_app.setAuthToken(res.auth_token);
                gl_app.setHash('#', true);
            } catch (e) {
                await gl_api.showError(e);
                gl_app.setHash('#', true);
            }
        });

        MSIcon.init({
            'dashboard': '<span class="material-symbols-outlined me-2">swap_driving_apps_wheel</span>',
            'account': '<span><i class="bi bi-person-circle"></i></span>',
            'company': '<span><i class="bi bi-building-fill"></i></span>',
            'key': '<i class="fa-solid fa-key"></i>',
            'ticket': '<i class="fa-solid fa-house"></i>',
            'address-card': '<i class="fa-solid fa-address-card"></i>',
            'table-list': '<i class="fa-solid fa-table-list"></i>',
            'recycle': '<i class="fa-solid fa-recycle"></i>',
            'file-invoice-dollar': '<i class="fa-solid fa-file-invoice-dollar"></i>',
            'user-secret': '<i class="fa-solid fa-user-secret"></i>',
            'gear': '<i class="fa-solid fa-gear"></i>',
            'building': '<i class="fa-solid fa-building"></i>',
            'file-image': '<i class="fa-solid fa-file-image"></i>',
            'print': '<i class="fa-solid fa-print"></i>',
            'logout': '<i class="bi bi-door-closed-fill fs-5 me-2"></i>',
            // 'logout': '<img src="/--sitevers--/assets/webpack-image.png" style="width:30px;height:30px;"/>',
        });

        // Load modules
        const auth_token = this.authToken();
        if (auth_token && Array.isArray(auth_token.modules)) {
            const promises = [];
            auth_token.modules.forEach((module) => {
                try {
                    promises.push(import(`./modules/${module}/index`));
                } catch (e) {
                    console.warn(`could not load module ${module}`);
                }
            });
            await Promise.all(promises);
        }

        this.onHashChanged();

        this.customizeSidebarItems();
    }

    /*
    Navigation
   */
    registerPane(iPage, iHash) {
        super.registerPane(iPage, iHash, this.m_main_page);
    }

    defaultHash() {
        if (!this.authToken()) {
            return { hash: 'welcome' };
        }
        if (this.getPage('dashboard')) {
            return { hash: 'dashboard' };
        }
        if (this.getPage('consumer_list')) {
            return { hash: 'consumer_list' };
        }
        console.warn('no hash available');
        return { hash: 'welcome' };
    }

    onQueryChanged(iQuery) {
        if (!this.authToken()) {
            if (iQuery.hash.substring(0, 5) != 'login' && iQuery.hash != 'welcome' && iQuery.hash != 'user_order' && iQuery.hash != 'legal_notices' && iQuery.hash != 'ims_cgv' && iQuery.hash != 'about') {
                this.setHash('#welcome');
                return;
            }
        }
        super.onQueryChanged(iQuery);
    }

    /*
    Authentication
   */
    authToken() {
        if (this.m_auth_token === undefined) {
            console.log('loading auth_token');
            try {
                this.m_auth_token = JSON.parse(localStorage.getItem('auth_token'));
            } catch (e) {
                this.m_auth_token = false;
                console.warn('auth_token is broken', localStorage.getItem('auth_token'));
            }
        }
        if (this.m_auth_token && this.m_auth_token.expiration_ts * 1000 < Date.now()) {
            localStorage.removeItem('auth_token');
            this.m_auth_token = false;
            console.log('auth_token expired');
        }
        if (!this.m_auth_token) {
            console.log('auth_token is FALSE');
        }
        return this.m_auth_token;
    }

    async refreshAuthToken() {
        const res = await gl_api.refreshAuthToken();
        gl_app.setAuthToken(res.auth_token);
        return res.auth_token;
    }

    authTokenId() {
        const token = this.authToken();
        return token ? token.id : false;
    }

    setAuthToken(iToken) {
        if (iToken) {
            this.m_auth_token = iToken;
            localStorage.setItem('auth_token', JSON.stringify(iToken));
        } else {
            localStorage.removeItem('auth_token');
            this.m_auth_token = false;
        }
    }

    /*
    Sidebar
    */
    updateUserMenu() {
        if (this.m_main_page.isCreated()) {
            this.m_main_page.updateUserMenu();
        }
    }

    customizeSidebarItems() {
        // Sélectionnez tous les éléments li avec la classe 'nav-item sidebar_item'
        const $items = $(document).find('.nav-item .sidebar_item');

        // Ajoutez la classe 'd-flex' à ces éléments
        $items.addClass('d-flex');

        // Ajoutez une div avec la classe 'sideRow' à chaque élément
        $items.each(function () {
            const $item = $(this);
            const $sideRow = $('<div/>').addClass('sideRow');

            // Déplacez le contenu actuel de l'élément li dans la nouvelle div
            $sideRow.append($item.contents());

            // Ajoutez la nouvelle div dans l'élément li
            $item.append($sideRow);
        });
    }
}
