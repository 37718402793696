import {MSApp} from './MSApp';

export class MSAppPage {

    m_ref; //Une référence à la page.
    m_query; //Un objet contenant les paramètres de la query string de l'URL associée à la page.
    $m_div; // Une référence jQuery à l'élément <div> qui représente visuellement la page.

    //initialise la référence de la page avec la valeur passée en paramètre et initialise l'objet m_query avec un objet vide.
    constructor(iRef) {
        this.m_ref = iRef;
        this.m_query = {};
    }

    /**  used for backward compatibility
     *  Cette méthode est utilisée pour créer l'élément <div> représentant visuellement la page. Elle initialise également $m_div avec cet élément et appelle la méthode onCreate 
    */
    $create(iQuery) {
        this.$m_div = $("<div/>").addClass(this.m_type).attr({ "id": `${this.m_type}_${this.m_ref}`});
        this.onCreate(iQuery);
        return this.$m_div;
    }
    //Retourne true si la page a été créée (c'est-à-dire si $m_div n'est pas indéfini).
    isCreated() {
        return this.$m_div != undefined;
    }

    // Obsolete
    onCreate(iQuery) {
    }
    //Méthode appelée après la création de la page.
    onAfterCreate(iQuery) {
    }
    //Méthode appelée avant l'ouverture de la page
    onBeforeOpen(iQuery) {
    }
    //Méthode appelée lors de l'ouverture de la page
    onOpen(iQuery) {
    }
    //Méthode appelée après l'ouverture de la page.
    onAfterOpen(iQuery) {
    }
    //Méthode appelée lorsqu'il y a un changement dans la query string de l'URL associée à la page.
    onQueryChanged(iQuery) {
    }
    // Méthode pour mettre à jour les paramètres de la query string de l'URL associée à la page et appeler onQueryChanged.
    query(iQuery) {
        this.m_query = iQuery;
        this.onQueryChanged(iQuery);
    }
    // Méthode appelée lorsque la page est fermée.
    onClose() {
    }

    // updateWindowHash(iQuery) {
    //     const params = new URLSearchParams();
    //     for (const key in iQuery) {
    //         params.set(key, iQuery[key]);
    //     }
    //     window.location.hash = this.m_ref + '?' + params.toString();
    // }
}
