import { MSAppPage } from '../../ms_front_lib/app/MSAppPage';
import "./PageMain.css";
import './PageWelcome.css';
import page_content from "./PageWelcome.html";

export class PageWelcome extends MSAppPage {

    $create(iQuery) {
        return $(page_content);
    }
    onOpen(iQuery) {
        function addMetaDescription() {
            const descriptionContent = "Découvrez iMS Pass, la plateforme pour gérer facilement vos contremarques cinéma. Commandez, partagez et profitez de vos chèques CE dématérialisés rapidement et simplement.";
            let metaDescription = $('meta[name="description"]');
    
            if (metaDescription.length) {
                metaDescription.attr('content', descriptionContent);
            } else {
                metaDescription = $('<meta>').attr('name', 'description').attr('content', descriptionContent);
                $('head').append(metaDescription);
            }
        }
        addMetaDescription();
  
    }
}

// $(document).ready(function () {
//     addMetaDescription()
// })