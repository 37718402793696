import '../css/main.css';
import '../css/forms.css';

function globalize(iObj) {
    for (const i in iObj) {
        window[i] = iObj[i];
    }
}

// Global npm includes
import moment from 'moment';
window.moment = moment;
const jQuery = require('jquery');
window.$ = jQuery;
import * as Popper from '@popperjs/core';
window.Popper = Popper;
import * as md5 from 'md5';
window.md5 = md5;

// Global includes
import * as utils from '../ms_front_lib/utils'; 
globalize(utils);
import * as forms from '../ms_front_lib/forms/Forms'; 
globalize(forms);
import * as modals from '../ms_front_lib/components/MSModal'; 
globalize(modals);
import * as toast from './toast'; 
globalize(toast);

// Localization (FR hardcoded)
const lang = require('../lang/fr_FR/LC_MESSAGES/fr_FR.json'); 
window._l = (iText) => lang[iText] ?? iText;
window._h = (iText) => utils.htmlent(lang[iText] ?? iText);

// Load theme
let theme = localStorage.getItem('theme');
if (!theme) {
    theme = 'default';
    localStorage.setItem('theme', theme);
}

import(`../ms_front_lib/themes/${theme}/index`); 

document.title = _l('app_name');

// App

import {App} from './app';
import {Api} from './api';

$(function () {
    window.gl_app = new App();
    window.gl_api = new Api();
    window.gl_app.start();
});

window.onerror = (errorMsg, url, lineNumber) => {
    console.error('OOPS onerror ',{
        errorMsg : errorMsg,
        url : url,
        lineNumber : lineNumber,
    });
    return false;
};

window.onunhandledrejection = (e) => {
    console.error('OOPS onunhandledrejection '+e);
};

// $(window).on("mouseover", (e) => {
//     if ($(e.target).hasClass('clipboard_over')) {
//         window.$clipboard_copy_btn = $('<svg viewBox="0 0 24 24" class="clipboard_copy_btn"><path d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z"></path></svg>');
//         window.$clipboard_copy_btn.appendTo($(e.target));
//         //console.log('mouseover');
//     }
// });

// $(window).on("mouseout", (e) => {
//     if ($(e.target).hasClass('clipboard_over')) {
//         window.$clipboard_copy_btn.remove();
//         window.$clipboard_copy_btn = false;
//         //console.log('mouseout');
//     }
// });

$(window).on("click", (e) => {
    if ($(e.target).hasClass('clipboard_over')) {
        navigator.clipboard.writeText($(e.target).text());
    }
});

