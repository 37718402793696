var __ms_uid = Date.now();

export function ms_uid() {
	return __ms_uid++;
}

export function htmlent(iText) {
	var html = $('<div/>').text("" + iText).html();
	return html.replace("\n", "<br>").replace(/"/g, "&quot;");
}

export function gettext_localize(iText) {
    return iText.replace( /\b_[lh]\("[^"]+"\)/g, (str) => {
        const key = str.substring(4,str.length - 2);
        return str[1] == 'h' ? _h(key) : _l(key);
    });
}

export function number_format(number, decimals, dec_point, thousands_sep) {
	// Strip all characters but numerical ones.
	number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
	var n = !isFinite(+number) ? 0 : +number,
		prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
		sep = (typeof thousands_sep === 'undefined') ? '' : thousands_sep,
		dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
		s = '',
		toFixedFix = function (n, prec) {
			var k = Math.pow(10, prec);
			return '' + Math.round(n * k) / k;
		};
	// Fix for IE parseFloat(0.55).toFixed(0) = 0;
	s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
	if (s[0].length > 3) {
		s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
	}
	if ((s[1] || '').length < prec) {
		s[1] = s[1] || '';
		s[1] += new Array(prec - s[1].length + 1).join('0');
	}
	return s.join(dec);
}

export function currency_format(currency, number, decimals, dec_point, thousands_sep) {
	var amount = "" + number_format(number, decimals, dec_point, thousands_sep);
	switch (currency) {
		case 'MAD':
			return amount + " DH";
		case 'CHF':
			return amount + " CHF";
		case 'EUR':
			return amount + " €";
		default:
			return '$' + amount + " USD";
	}
}

export function currency_html_format(currency, number, decimals, dec_point, thousands_sep) {
	var amount = "" + number_format(number, decimals, dec_point, thousands_sep);
	switch (currency) {
		case 'MAD':
			return amount + "<span class='small'> DH</span>";
		case 'CHF':
			return amount + "<span class='small'> CHF</span>";
		case 'EUR':
			return amount + " &euro;";
		default:
			return '$' + amount + " USD";
	}
}

// ==================================================
// ========== Test le contenu d'un objet ============
// ==================================================
export function is_empty(parameter) {

	var obj = parameter.obj;

	for (var key in obj) {
		if (obj.hasOwnProperty(key))
			return false;
	}
	return true;
};

window.String.prototype.format = function () {
	let a = this;
	for (const k in arguments) {
		a = a.replace("{" + k + "}", arguments[k]);
	}
	return a
}

