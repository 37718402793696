import './cedematSidebar.css';
import {MSIcon} from '../../ms_front_lib/components/MSIcon';

export class CeDematSidebar {
   
    $create(iConfig) {
        const $ul = $("<ul/>").addClass("sidebar_accordion navbar-nav");
        if (iConfig.class) {
            $ul.addClass(iConfig.class);
        }
        iConfig.items.forEach((item) => {
            this.$create_item(item).appendTo($ul);
        });

        return $ul;
    }

    $create_item(iItem) {
        const collapse_id = "collapse_" + ms_uid();
        let $li = $("<li/>").addClass("nav-item sidebar_item d-flex flex-row-reverse justify-content-center align-items-center");
        if (iItem.id) {
            $li.attr('item_id', iItem.id);
        }
        if (iItem.class) {
            $li.addClass(iItem.class); // Ajout des classes personnalisées
        }
        const $wrap = $("<div/>").addClass("wrap").appendTo($li);
        let $link;
        if (typeof (iItem.items) == "object") {
            $link = $("<a/>").addClass("link btn-toggle").attr({ "data-bs-toggle": "collapse", "href": "#" + collapse_id, "aria-expanded": false }).appendTo($wrap);
        } else {
            $link = $("<a/>").attr({ "id": "link_" + iItem.href }).addClass("autoclose link").appendTo($wrap);
            if (iItem.href) {
                $link.attr("href", iItem.href);
            }
        }
        if (iItem.icon) {
            MSIcon.$(iItem.icon).appendTo($link);
        }
        if (iItem.text) {
            $("<span/>").addClass("text").text(iItem.text).appendTo($link);
        }

        // Ajout de la balise <div>
        $("<div/>").addClass("custom-div").html('<div class="siderow_md d-flex justify-content-center align-items-center"></div>').appendTo($li);

        if (typeof (iItem.items) == "object") {
            const $collapse = $("<div/>").addClass("collapse").attr({ "id": collapse_id }).appendTo($wrap);
            const $ul_collapse = $("<ul/>").addClass("list-group btn-toggle-nav").appendTo($collapse);
            for (const i in iItem.items) {
                this.$create_sub_item(iItem.items[i]).appendTo($ul_collapse);
            }
        }
        return $li;
    }

    $create_sub_item(iItem) {
        const $li = $("<li/>").addClass("collapse-item sidebar_sub_item");
        if (iItem.class) {
            $li.addClass(iItem.class); // Ajout des classes personnalisées
        }
        const $link = $("<a/>").attr({ "id": "link_" + iItem.href }).addClass("autoclose collapse-link").appendTo($li);
        if (iItem.href) {
            $link.attr("href", iItem.href);
        }
        if (iItem.icon) {
            MSIcon.$(iItem.icon).appendTo($link);
        }
        if (iItem.text) {
            $("<span/>").addClass("collapse-text").text(iItem.text).appendTo($link);
        }
        return $li;
    }
}
