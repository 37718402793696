export function showOverlay() {
    const overlay = document.getElementById('wait_overlay');
    if (overlay) {
        overlay.style.display = 'flex';
    }
}

export function hideOverlay() {
    const overlay = document.getElementById('wait_overlay');
    if (overlay) {
        overlay.style.display = 'none';
    }
}