import { MSForm, MSFormFieldContainer } from './Forms';

export class AccordionField extends MSFormFieldContainer {

    $createContainer() {
        return $("<div/>").addClass("ms form row");
    }

    $create() {
        this.$m_element = $("<div/>").addClass("ms accordion").attr('id', `accordion_${this.m_uid}`);
        if (this.m_cfig.items) {
            this.m_cfig.items.forEach((item_cfig) => {
                const uid = ms_uid();
                const $item = $("<div/>").addClass("ms accordion-item").appendTo(this.$m_element);
                const $header = $("<h2/>").addClass("ms accordion-header").attr('id', `accordion_header_${uid}`).appendTo($item);
                const label = htmlent(item_cfig.label);
                $header.html(`<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#accordion_collapse_${uid}" aria-expanded="true" aria-controls="accordion_collapse_${uid}">${label}</button>`);
                const show = item_cfig.show ? 'show' : '';
                const $body_container = $(`<div id="accordion_collapse_${uid}" class="accordion-collapse collapse ${show}" aria-labelledby="accordion_header_${uid}" data-bs-parent="#accordion_${this.m_uid}">`).appendTo($item);
                const $body = $("<div/>").addClass("ms accordion-body").appendTo($body_container);
                const container_config = {
                    type: 'group',
                    fields: item_cfig.fields,
                };
                const field = MSForm.createField(container_config, this);
                if (field) {
                    const $field = this.$createChild(field);
                    $field.appendTo($body);
                    $body[0].field = field;
                    field.onCreated(item_cfig);
                    this.m_fields.push(field);
                }
            });
        }
        return this.$m_element;
    }
}

export class CardField extends MSFormFieldContainer {

    $createContainer($iParent) {
        const $res = $("<div/>").addClass("card form_card").appendTo($iParent);
        const $body = $("<div/>").addClass("card-body").appendTo($res);
        if (this.m_cfig.title) {
            $("<h5/>").addClass("card-title").text(this.m_cfig.title).appendTo($body);
            if (this.m_cfig.subtitle) {
                $("<h6/>").addClass("card-subtitle mb-2 text-muted").text(this.m_cfig.subtitle).appendTo($body);
            }
        }
        return $body;
    }
}

export function load() {
    MSForm.registerFieldClass('group', MSFormFieldContainer);
    MSForm.registerFieldClass('accordion', AccordionField);
    MSForm.registerFieldClass('card', CardField);
}