import { MSForm, MSInputField } from './Forms';

export class MSFloatingLabelField extends MSInputField {

    $create() {
        this.m_input_type = this.m_cfig.type.substr(3); // remove 'fl_'
        const $div = $('<div class="form-floating ' + (this.m_cfig.outer_classes ? this.m_cfig.outer_classes : "") +'"></div>');
        const html = this.html();
        $(html).appendTo($div);
        return $div;
    }

    onCreated(iFieldConfig) {
        if (! this.$m_input) {
            this.$m_input = this.$m_element.find('input');
        }
        super.onCreated(iFieldConfig);
    }

    html() {
        let input = '<input type="' + this.m_input_type + '" class="form-control' + (this.m_cfig.classes ? (' ' + this.m_cfig.classes) : "") + '"' + this.html_props() + ' id="ms_form_uid-' + this.m_uid + '" key="' + this.m_cfig.key + '" name="' + this.m_cfig.key + '"';
        //if (this.m_cfig.place_holder) {
        input += ' placeholder="' + (this.m_cfig.place_holder ? this.m_cfig.place_holder : "placeholder") + '"';
        //}
        if (this.m_cfig.disable_autofill) {
            input += ' autocomplete="new-password"';
        }
        if (this.m_cfig.title) {
            //				input += ' title="'+this.m_cfig.title+'"';
        }
        if (this.m_cfig.readonly) {
            input += ' readonly';
        }
        if (this.m_cfig.autofocus) {
            input += ' autofocus';
        }
        input += this.validation_props() + '>';
        return this.labeledHtml(input) + this.help_html();
    }

    labeledHtml(iHtml) {
        if (!this.m_cfig.label) {
            return iHtml;
        }
        return iHtml + '<label for="ms_form_uid-' + this.m_uid + '">' + this.label() + '</label>';
    };
}

export class MSFloatingLabelSelect extends MSFloatingLabelField {
    html() {
        let html = '<select class="form-select ' + (this.m_cfig.classes ? (' ' + this.m_cfig.classes) : "") + '"' + this.html_props() + ' id="ms_form_uid-' + this.m_uid + '" key="' + this.m_cfig.key + '" name="' + this.m_cfig.key + '"';
        html += this.validation_props() + '>';
        for (const k in this.m_cfig.options) {
            html += `<option value="${k}">` + htmlent(this.m_cfig.options[k]) + `</option>`;
        }
        html += '</select>';
        return this.labeledHtml(html) + this.help_html();
    }

    onCreated(iFieldConfig) {
        if (! this.$m_input) {
            this.$m_input = this.$m_element.find('select');
        }
        super.onCreated(iFieldConfig);
    }

    setValue(iValue) {
        this.$m_element.find('select').val(iValue);
    }

    getValue() {
        return this.$m_element.find('select').val();
    }
}

export function load() {
    MSForm.registerFieldClass('fl_tel', MSFloatingLabelField);
    MSForm.registerFieldClass('fl_text', MSFloatingLabelField);
    MSForm.registerFieldClass('fl_email', MSFloatingLabelField);
    MSForm.registerFieldClass('fl_url', MSFloatingLabelField);
    MSForm.registerFieldClass('fl_password', MSFloatingLabelField);
    MSForm.registerFieldClass('fl_search', MSFloatingLabelField);
    MSForm.registerFieldClass('fl_date', MSFloatingLabelField);
    MSForm.registerFieldClass('fl_month', MSFloatingLabelField);
    MSForm.registerFieldClass('fl_week', MSFloatingLabelField);
    MSForm.registerFieldClass('fl_time', MSFloatingLabelField);
    MSForm.registerFieldClass('fl_datetime-local', MSFloatingLabelField);
    MSForm.registerFieldClass('fl_number', MSFloatingLabelField);
    MSForm.registerFieldClass('fl_select', MSFloatingLabelSelect);
}

