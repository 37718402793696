import { MSForm, MSInputField, MSFormField } from './Forms';

export class MSCheckField extends MSInputField {
    html() {
        return '<div class="form-check">' + this.labeledHtml(`<input class="form-check-input" type="${this.m_cfig.type}" id="ms_form_uid-${this.m_uid}">`) + this.help_html() + "</div>";
    }
    
    setValue(iValue) {
        this.$m_input.prop('checked', iValue ? true : false);
        // $('#ms_form_uid-' + this.m_uid)[0].checked = (iValue ? true : false);
    }

    getValue() {
        return this.$m_input.is(':checked');
        // return $('#ms_form_uid-' + this.m_uid).is(':checked');
    }
}

export class MSSwitchField extends MSCheckField {
    html() {
        return '<div class="form-check form-switch">' + this.labeledHtml(`<input class="form-check-input" type="checkbox" id="ms_form_uid-${this.m_uid}">`) + this.help_html() + "</div>";
    }
}

export class MSRadiosField extends MSFormField {

    $create() {
        let html = '';
        let i = 0;
        for (const k in this.m_cfig.options) {
            i++;
            html += `
            <div class="form-check">
            <input class="form-check-input" type="radio" name="${this.m_uid}" id="ms_form_uid-${this.m_uid}-${i}" xvalue="${k}">
            <label class="form-check-label" for="ms_form_uid-${this.m_uid}-${i}">` + htmlent(this.m_cfig.options[k]) + `</label>
            </div>`;
        }

        const $res = $(html);
        //$res.find('input').changed((e) => this.m_form.on_changed(e));

        return $res;
    }

    setValue(iValue) {
        const $obj = this.$m_element.find(`input[xvalue="${iValue}"]`);
        if ($obj.length) {
            $obj[0].checked = true;
        }
    }

    getValue() {
        return this.$m_element.find('input:checked').attr('xvalue');
    }
}

export class MSHtmlField extends MSFormField {
    html() {
        return this.m_cfig.html;
    };
}

export class MSSeparatorField extends MSFormField {
    html() {
        if (!this.m_cfig.label) {
            return '<hr>';
        }
        return '<div class="text-divider">' + htmlent(this.m_cfig.label) + '</div>'
    };
}

export class MSColorField extends MSFormField {
    html() {
        let $res=$("<div/>").addClass("d-flex");
        if(this.m_cfig.label){
            $("<label/>").addClass("label").text(this.m_cfig.label).appendTo($res)
        }
        $("<input/>").attr({"type":"color", "id":"ms_form_uid-"+this.m_uid}).addClass(this.m_cfig.class).appendTo($res);
        return $res;
    };
    setValue(iValue) {
        if($('#ms_form_uid-' + this.m_uid).length>0){
            $('#ms_form_uid-' + this.m_uid)[0].value = (iValue ? iValue : "#000000");
        }
        
    }
    getValue() {
        return $('#ms_form_uid-' + this.m_uid).val();
    }
}

export function load() {
    MSForm.registerFieldClass('checkbox', MSCheckField);
    MSForm.registerFieldClass('radios', MSRadiosField);
    MSForm.registerFieldClass('switch', MSSwitchField);
    MSForm.registerFieldClass('html', MSHtmlField);
    MSForm.registerFieldClass('separator', MSSeparatorField);
    MSForm.registerFieldClass('color', MSColorField);
}