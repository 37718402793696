import { MSForm } from '../../ms_front_lib/forms/Forms';
import { MSAppPage } from '../../ms_front_lib/app/MSAppPage';
import './PageAccount.css';
import { showOverlay, hideOverlay } from './overlay';

export class PageAccount extends MSAppPage {
    m_form;
    $m_submit_btn;
    $m_formElement;

    $create() {
        const $res = $('<div />');
        $res.html(this.getInitialHtml());

        const userData = this.getUserData();
        if (!userData) return $res;

        this.createForm($res, userData);
        this.createToggleButton($res);
        this.createSubmitButton($res, userData);
        this.populateUserInfo($res, userData);

        return $res;
    }

    getInitialHtml() {
        return `
            <div class='container default_height animate__animated animate__fadeInLeft'>
                <div class='d-flex align-items-center'>
                    <div class='sideRow'></div>
                    <h2 class='my-5 ms-3 text-start'>Mon compte</h2>
                </div> 
                <div class='acc_container_main'>
                    <div class='acc_container_logo'>
                        <i class="bi bi-person-circle user_account_icon"></i>
                    </div>
                    <div class='acc_container_info'>
                        <h3 class="form-title text-secondary text-center" style="display: none;"><span class="default_color text-uppercase">R</span>enseigner vos informations</h3>
                        <h3 class="form-title-2 mt-3 text-secondary text-center"><span class="default_color  text-uppercase">V</span>os informations</h3>
                        <div class="container user-info my-5 fs-6"></div>
                        <div class="form-container"></div>
                    </div>
                </div>
                <div class="button-container d-flex">
                    <button class=" standard_btn border border-0 p-1 submit-button">Enregistrer</button>
                    <button class=" standard_btn border border-0 p-1  ms-2 toggle-button acc_modif">Modifier les informations</button>
                </div>
            </div>
        `;
    }

    getUserData() {
        return gl_app.authToken();
    }

    createForm($res, userData) {
        this.m_form = new MSForm({
            fields: [
                { type: 'fl_text', key: 'first_name', label: 'Prénom', value: userData.user.first_name || '' },
                { type: 'fl_text', key: 'last_name', label: 'Nom de famille', value: userData.user.last_name || '' },
                { type: 'fl_number', key: 'phone_number', label: 'Numéro de téléphone', value: userData.user.phone_number || '' },
            ],
        });
        this.$m_formElement = this.m_form.$create();
        this.$m_formElement.appendTo($res.find('.form-container'));
        $res.find('.form-container input.form-control').addClass('input_style');
        this.$m_formElement.hide();
    }

    createToggleButton($res) {
        const toggleButton = $res.find('.toggle-button');
        toggleButton.on('click', () => {
            const isVisible = this.$m_formElement.is(':visible');
            this.$m_formElement.toggle();
            this.$m_submit_btn.toggle();
            $res.find('.user-info').toggle();
            $res.find('.form-title').toggle(!isVisible);
            $res.find('.form-title-2').toggle(isVisible);
            toggleButton.text(isVisible ? 'Modifier les informations' : 'Retour');
            const accContainerInfo = $res.find('.acc_container_info');

            accContainerInfo.addClass('animate__animated animate__fadeInRight');
            accContainerInfo.one('animationend', () => {
                accContainerInfo.removeClass('animate__animated animate__fadeInRight');
            });
        });
    }

    createSubmitButton($res, userData) {
        this.$m_submit_btn = $res.find('.submit-button');
        this.m_form.setValidateBtn(this.$m_submit_btn);
        this.$m_submit_btn.on('click', async (event) => {
            event.preventDefault();

            const formattedData = this.m_form.getPatch();

            try {
                showOverlay(); // Show overlay
                const response = await gl_api.patchAccount(formattedData).catch(error => { throw new Error('Patch account error: ' + error) });
                this.m_form.setValue(response);

                try {
                    await gl_app.refreshAuthToken();
                    window.location.reload();
                } catch (error) {
                    console.error('erreur refreshAuthToken', error);
                }
            } catch (error) {
                console.error('erreur maj compte:', error);
            } finally {
                hideOverlay();
            }
        });

        this.$m_submit_btn.hide();
    }

    populateUserInfo($res, userData) {
        const userInfo = $res.find('.user-info');
        const user = userData.user;

        const isUserInfoIncomplete = !user.first_name || !user.last_name || !user.phone_number;

        if (isUserInfoIncomplete) {
            this.$m_formElement.show();
            this.$m_submit_btn.show();
            userInfo.hide();
            $res.find('.acc_modif').hide();
        } else {
            userInfo.html(`
                <p class="fst-italic text-capitalize acc_p"><span class="fw-bolder"><i class="bi bi-pencil me-2"></i>Prénom: </span>${user.first_name}</p>
                <p class="fst-italic text-capitalize acc_p"><span class="fw-bolder"><i class="bi bi-pencil-fill me-2"></i>Nom de famille: </span>${user.last_name}</p>
                <p class="fst-italic acc_p"><span class="fw-bolder text-capitalize"><i class="bi bi-envelope-check me-2"></i>Email: </span>${user.email}</p>
                <p class="fst-italic acc_p"><span class="fw-bolder text-capitalize"><i class="bi bi-telephone-fill me-2"></i>Numéro de téléphone: </span>${user.phone_number}</p>
            `);
            userInfo.show();
        }
    }

    addMetaDescription() {
        const descriptionContent = 'Page compte - Gérez vos informations personnelles, mettez à jour vos coordonnées et visualisez les détails de votre compte utilisateur.';
        let metaDescription = $('meta[name="description"]');

        if (metaDescription.length) {
            metaDescription.attr('content', descriptionContent);
        } else {
            metaDescription = $('<meta>').attr('name', 'description').attr('content', descriptionContent);
            $('head').append(metaDescription);
        }
    }

    onOpen(iQuery) {
        this.addMetaDescription();
        const auth_token = gl_app.authToken();
        this.m_form.setValue(auth_token.user);

        // Vérifier et mettre à jour le hash lors du chargement de la page
        // this.checkAndUpdateHash();
    }
}

//     checkAndUpdateHash() {
//         const currentHash = window.location.hash;
//         if (!currentHash.includes('page_account')) {
//             this.updateHash('page_account');
//         }
//     }

//     updateHash(hash) {
//         const url = new URL(window.location);
//         url.hash = hash;
//         window.history.replaceState({}, '', url);
//     }
// }

// window.addEventListener('hashchange', () => {
//     const currentHash = window.location.hash;
//     if (currentHash.includes('page_account')) {
//         const page = new PageAccount();
//         page.onOpen();
//     }
//});
