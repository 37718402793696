import { MSAppPage } from '../../ms_front_lib/app/MSAppPage';
import Mustache from "mustache";
import "./PageCgv.css";
import page_content from "./PageCgv.html";

/*
http://localhost:8081/#login?email=landre3567@gmail.com&ws_stage=local&db_stage=local
*/

export class PageCgv extends MSAppPage {

    $create(iQuery) {
        let html = gettext_localize(page_content);
        html = Mustache.render(html, (id) => {
            console.warn("mustache:" + id);
            return id;
        });
        const $res = $(html);
        this.$m_pane_container = $res.find("#pane_container");
        return $res;
    }
    onOpen(iQuery) {
        function addMetaDescription() {
            const descriptionContent = "Découvrez les conditions générales de vente (CGV) de ImsPass, incluant l'application des conditions, la description des services, les modalités d'annulation et de remboursement, la sécurisation des paiements, et les obligations d'inscription et d'identification.";
            let metaDescription = $('meta[name="description"]');

            if (metaDescription.length) {
                metaDescription.attr('content', descriptionContent);
            } else {
                metaDescription = $('<meta>').attr('name', 'description').attr('content', descriptionContent);
                $('head').append(metaDescription);
            }
        }
        $(function () {
            if ($('.footerPage').length === 0) {
                $('.conditional_side_nav').show()
                $('#conditional_footer_cgv').show()
            } else {
                $('.conditional_side_nav').hide()
                $('#conditional_footer_cgv').hide()
            }
        });
        $(function () {

            $('.bi-arrow-up').parent('.btn').on('click', function () {
                $('.container-fluid').animate({
                    scrollTop: $('.container-fluid').scrollTop() + $('.scroll-top-anchor-cgv').position().top
                }, 'slow');
            });


            $('.bi-arrow-down').parent('.btn').on('click', function () {
                $('.container-fluid').animate({
                    scrollTop: $('.container-fluid').scrollTop() + $('.scroll-bottom-anchor-cgv').position().top - $('.container-fluid').height()
                }, 'slow');
            });
        });
        addMetaDescription();

    }
}
