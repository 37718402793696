export class MSIcon {
    static s_icons;

    static init (iIcons) {
        MSIcon.s_icons = iIcons;
    };

    static html(iIcon) {
        const res = MSIcon.s_icons[iIcon];
        return res ? res : '<span>[?]</span>';
    };

    static $(iIcon) {
        return $(MSIcon.html(iIcon));
    };
}

