import { MSAppPage } from '../../ms_front_lib/app/MSAppPage';
import JSONFormatter from 'json-formatter-js'

export class PageAuthToken extends MSAppPage {

    $create() {
        return $('<div/>');
    }

    onOpen() {
        const formatter = new JSONFormatter(gl_app.authToken());
        this.$m_div.html(formatter.render());
    }

}

