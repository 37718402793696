
export class Toast {

    static $s_container;

    static create(iParams) {

        if (!Toast.$s_container) {
            Toast.$s_container = $("<div/>").addClass("toast-container position-static").appendTo($("html"))
        }

        const params = $.extend({
            timeout: 2000,
            role: 'alert',
        }, iParams);

        const $toast = $('<class="toast" aria-live="assertive" aria-atomic="true"/>').attr({ "role": params.role }).appendTo(Toast.$s_container);
        const $flex = $('<div class="d-flex"/>').appendTo($toast);
        const $body = $('<div class="toast-body"/>').appendTo($flex);
        if (params.html) {
            $(params.html).appendTo($('body'));
        } else {
            $('<div/>').text(params.text).appendTo($('body'));
        }
        const $close_box = $('<button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>').appendTo($flex);

        $toast.show();

        if (this.timeout) {
            setTimeout(() => {
                $close_box.trigger('click');
            }, this.timeout)
        }
    }

    static success(iMessage) {
        Toast.create({
            role: "alert",
            class: "success",
            text: iMessage,
        });
    }

    static info(iMessage) {
        Toast.create({
            role: "alert",
            class: "info",
            text: iMessage,
        });
    }
    
    static warning(iMessage) {
        Toast.create({
            role: "alert",
            class: "warning",
            text: iMessage,
        });
    }

    static error(iMessage) {
        Toast.create({
            role: "alert",
            class: "error",
            text: iMessage,
        });
    }

}